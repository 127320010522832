<template>
    <div class="project-list__app">
        <div v-if="types.length || subjectAreas.length" class="filter" :class="{ 'filter--active': showFilter }">
            <span class="filter__toggle btn btn--toggle" :class="{ 'btn--toggle-active': showFilter }" @click="showFilter = !showFilter">
                <span class="btn__text">{{ showFilter ? $t('Dictionary.Projects.HideFilter') : $t('Dictionary.Projects.ShowFilter') }}</span>
                <span class="btn__icon">
                    <svg-inline name="arrow" />
                </span>
            </span>
            <div class="filter__inner">
                <div v-if="types.length" class="filter__col">
                    <span class="filter__headline">{{ $t('Dictionary.Projects.FilterTypes') }}</span>
                    <div class="filter__checkbox-list">
                        <div v-for="typeItem in types" :key="typeItem.id" class="filter__checkbox-wrap">
                            <input :id="'type-' + typeItem.key" v-model="selectedTypes" class="filter__checkbox" type="checkbox" :value="typeItem.key" @change="updateQueryParams" />
                            <label :for="'type-' + typeItem.key" class="filter__checkbox-label">{{ typeItem.name }}</label>
                        </div>
                    </div>
                </div>
                <div v-if="subjectAreas.length" class="filter__col">
                    <span class="filter__headline">{{ $t('Dictionary.Projects.FilterSubjectAreas') }}</span>
                    <div class="filter__checkbox-list">
                        <div v-for="subjectAreaItem in subjectAreas" :key="subjectAreaItem.id" class="filter__checkbox-wrap">
                            <input :id="'subjectArea-' + subjectAreaItem.key" v-model="selectedSubjectAreas" class="filter__checkbox" type="checkbox" :value="subjectAreaItem.key" @change="updateQueryParams" />
                            <label :for="'subjectArea-' + subjectAreaItem.key" class="filter__checkbox-label">{{ subjectAreaItem.name }}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card__list-wrap">
            <div v-if="filteredProjects.length" class="card__list">
                <div v-for="projectItem in displayedProjects" :key="projectItem.id" class="card__item">
                    <a :href="projectItem.url" :title="projectItem.name" class="card__item-inner">
                        <picture v-if="Object.keys(projectItem.imageUrls).length > 0" class="card__item-image-wrap">
                            <source :srcset="projectItem.imageUrls.xxl" media="(min-width: 1920px)" />
                            <source :srcset="projectItem.imageUrls.md" media="(min-width: 992px)" />
                            <source :srcset="projectItem.imageUrls.xs" media="(min-width: 480px)" />
                            <img class="card__item-image" loading="lazy" :src="projectItem.imageUrls.default" :alt="projectItem.imageAltText" />
                        </picture>
                        <div v-else class="card__item-image-wrap"></div>
                        <div class="card__item-content">
                            <div class="card__item-text">
                                <h2 v-if="moduleHasNoHeadline" class="card__item-headline">{{ projectItem.headline }}</h2>
                                <h3 v-else class="card__item-headline">{{ projectItem.headline }}</h3>
                                <span v-if="projectItem.teaser" class="card__item-teaser">
                                    <span class="card__item-teaser-inner">{{ projectItem.teaser }}</span>
                                </span>
                            </div>
                            <div class="card__item-icon">
                                <svg-inline name="arrow-with-line" />
                            </div>
                        </div>
                    </a>
                </div>
            </div>
            <p v-if="!isLoading && !filteredProjects.length" class="card__list-text">
                {{ $t('Dictionary.Projects.NoResults') }}
            </p>
            <div v-if="canLoadMore" class="card__list-link-wrap">
                <button class="card__list-link" @click="loadMore">{{ $t('Dictionary.Projects.LoadMore') }}</button>
            </div>
        </div>
        <div class="spinner-wrap" :aria-hidden="isLoading ? 'false' : 'true'">
            <div class="spinner"></div>
        </div>
    </div>
</template>

<script>
import apiService from '../../api';
import SvgInline from '../components/svg-inline.vue';
import { setupCard } from '../../components/card';

const initialItemsToShow = 6;

export default {
    name: 'ProjectList',
    components: {
        SvgInline
    },
    props: {
        pageId: {
            type: String,
            default: ''
        },
        moduleHasNoHeadline: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isLoading: false,
            showFilter: false,
            projects: [],
            types: [],
            subjectAreas: [],
            selectedTypes: [],
            selectedSubjectAreas: [],
            itemsToShow: initialItemsToShow
        };
    },
    computed: {
        filteredProjects() {
            const vm = this;
            return vm.projects
                .filter(projectItem => vm.selectedTypes.every(x => projectItem.types.map(y => y.key).includes(x)))
                .filter(projectItem => vm.selectedSubjectAreas.every(x => projectItem.subjectAreas.map(y => y.key).includes(x)));
        },
        displayedProjects() {
            return this.filteredProjects.slice(0, this.itemsToShow);
        },
        canLoadMore() {
            return this.itemsToShow < this.filteredProjects.length;
        }
    },
    watch: {
        '$route.query': 'updateFiltersFromQuery'
    },
    created() {
        this.getProjects();
    },
    methods: {
        getProjects() {
            const vm = this;
            vm.isLoading = true;

            apiService.projects.getProjects(vm.pageId)
                .then(response => {
                    vm.projects = response.data.projects;
                    vm.types = response.data.types;
                    vm.subjectAreas = response.data.subjectAreas;
                })
                .catch(errors => {
                    console.log(`Error (getProjects): ${errors}`);
                })
                .then(function () {
                    vm.isLoading = false;
                    setupCard();
                });
        },
        updateQueryParams() {
            const router = this.$router;
            const query = {
                types: this.selectedTypes.length ? this.selectedTypes : undefined,
                subjectAreas: this.selectedSubjectAreas.length ? this.selectedSubjectAreas : undefined
            };
            router.push({ query });
        },
        updateFiltersFromQuery() {
            const route = this.$route;
            this.selectedTypes = route.query.types ? (Array.isArray(route.query.types) ? route.query.types : [route.query.types]) : [];
            this.selectedSubjectAreas = route.query.subjectAreas ? (Array.isArray(route.query.subjectAreas) ? route.query.subjectAreas : [route.query.subjectAreas]) : [];
            this.itemsToShow = initialItemsToShow;
        },
        loadMore() {
            this.itemsToShow += initialItemsToShow;
        }
    }
};
</script>
