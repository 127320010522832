import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import i18n from '../vue/i18n';
import ProjectList from '../vue/components/project-list.vue';

const routes = [
    {
        path: '/:pathMatch(.*)*', // Matches any path
        component: ProjectList
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export function setupVue() {

    const app = createApp();

    // We need this to make sure "white-space: pre-line" still works
    app.config.compilerOptions.whitespace = 'preserve';

    app.component('ProjectList', ProjectList);

    app.use(i18n);
    app.use(router);
    app.mount('#app');
}
