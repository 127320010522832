
import Player from '@vimeo/player';

export function setupVimeoVideos(selector = '[data-action~="vimeo-video"]') {
    const videos = document.querySelectorAll(selector);

    if (videos.length) {

        videos.forEach(video => {
            const iframe = video.querySelector('iframe');

            if (iframe) {
                const player = new Player(iframe);

                player.ready()
                    .then(function() {
                        video.classList.add('video--initialized');
                    })
                    .catch(function() {
                        console.log('vimeo video couldn\'t load');
                    });

                player.on('play', function() {
                    video.classList.remove('video--loading');
                    video.classList.add('video--playing');
                });

                player.on('ended', function() {
                    video.classList.remove('video--playing');
                });

                const playTrigger = video.hasAttribute('data-video-play-trigger') ? document.getElementById(video.getAttribute('data-video-play-trigger')) : null;
                if (playTrigger) {
                    playTrigger.addEventListener('click', () => {
                        video.classList.add('video--loading');

                        player.play()
                            .then(() => {
                                player.setVolume(1);
                            })
                            .catch(error => {
                                console.log(error);
                            });

                    });
                }
            }
        });
    }
}
