export function setupCard(selector = '.card__item-inner') {
    const card = document.querySelectorAll(selector);

    if (card) {
        const anyHover = window.matchMedia('(any-hover: hover)');

        card.forEach(cardItem => {
            const cardItemTeaser = cardItem.querySelector('.card__item-teaser');

            if (anyHover.matches && cardItemTeaser) {
                cardItem.addEventListener('mouseenter', () => {
                    cardAnimate(cardItemTeaser, true);
                });
                cardItem.addEventListener('mouseleave', () => {
                    cardAnimate(cardItemTeaser);
                });
            }
        });
    }
}

function cardAnimate(cardItemTeaser, mouseEnter) {
    if (mouseEnter) {
        cardItemTeaser.style.height = `${cardItemTeaser.scrollHeight}px`;
    } else {
        cardItemTeaser.style.height = '0';
    }
}
